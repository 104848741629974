@import "../../theme";
@import "../../theme/media";

@mixin heading {
  margin: 1.5rem 0;
  text-align: center;
  text-transform: uppercase;
}

.title {
  @include heading;
  @include font(AmaticSC, bold);
  font-size: 5rem;
  margin-top: 0;

  @include media(lg) {
    font-size: 3rem;
  }

  @include media(sm) {
    font-size: 2rem;
  }
}

.subtitle {
  @include heading;
  @include font(Museo, 500);
  font-size: 1.75rem;
  margin: 0;

  @include media(lg) {
    font-size: 1.5rem;
  }

  @include media(sm) {
    font-size: 1rem;
  }
}
