@import "../../theme";
@import "../../theme/media";

.subtitleContainer {
  @include font(AmaticSC, bold);
  font-size: 2.8rem;
  margin: -2rem 0 1rem 0;
  text-align: center;

  @include media(lg) {
    font-size: 2.5rem;
    margin-top: -1.75rem;
  }

  @include media(md) {
    font-size: 2rem;
    margin-top: -1.5rem;
  }

  @include media(sm) {
    font-size: 1.5rem;
    margin-top: -1.25rem;
  }

  @include media(xs) {
    font-size: 1.5rem;
    margin-top: -1rem;
  }
}

.subtitle {
  position: relative;
}

.book {
  left: calc(100% + 0.5rem);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;

  @include media(lg) {
    width: 1.5rem;
  }
}
