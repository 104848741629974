@import "../../theme";
@import "../../theme/media";

.title {
  @include font(AmaticSC, bold);
  font-size: 4.7rem;
  line-height: 4.6rem;
  margin-top: 6rem;
  text-align: center;

  @include media(lg) {
    font-size: 4rem;
    line-height: 3.9rem;
    margin-top: 4rem;
  }

  @include media(md) {
    font-size: 3rem;
    line-height: 2.9rem;
    margin-top: 3rem;
  }

  @include media(xs) {
    font-size: 2.5rem;
    line-height: 2.4rem;
    margin-top: 2rem;
  }
}
