@import "../../theme";
@import "../../theme/media";

.background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  path {
    transition-duration: 150ms;
    transition-property: fill;
  }
}

.button {
  @include font(AmaticSC, bold);
  align-items: center;
  border-radius: 50%;
  color: $green03;
  display: flex;
  font-size: 1.5rem;
  height: 2.25rem;
  justify-content: center;
  position: relative;
  width: 2.25rem;

  &:not([disabled]):hover {
    path {
      fill: darken($grayEE, 10%);
    }
  }

  &:not([disabled]).active {
    color: $white;

    path {
      fill: $green03;
    }
  }

  &[disabled] {
    opacity: 0.25;
  }

  @include media(lg) {
    font-size: 1.25rem;
    height: 1.75rem;
    width: 1.75rem;
  }

  @include media(sm) {
    font-size: 1rem;
    height: 1.5rem;
    width: 1.5rem;
  }
}

.label {
  z-index: 1;
}
