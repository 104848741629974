@import "./theme/theme";
@import "./theme/media";

@font-face {
  font-family: AmaticSC;
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/AmaticSC-Regular.ttf") format("truetype");
}

@font-face {
  font-family: AmaticSC;
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/AmaticSC-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Museo;
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/MuseoSans-300.otf") format("opentype");
}

@font-face {
  font-family: Museo;
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/MuseoSans-500.otf") format("opentype");
}

@font-face {
  font-family: Museo;
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/MuseoSans-700.otf") format("opentype");
}

* {
  box-sizing: border-box;
}

body {
  font-style: normal;
  font-weight: normal;
  margin: 0;
}

button {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  padding: 0;

  &[disabled] {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
}

a {
  border-bottom: 1px solid transparent;
  color: inherit;
  text-decoration: none;
  transition: 250ms border-bottom;

  &:focus {
    outline: none;
  }
}

input {
  max-width: 100%;
  min-width: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }
}

img {
  display: inline-block;
}

table {
  border-collapse: collapse;
}

td {
  vertical-align: middle;
}

#root {
  align-items: stretch;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  min-height: 100vh;
}
