@import "../../theme";
@import "../../theme/media";

$transition-duration: 150ms;

.background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  path:nth-of-type(2) {
    transition-duration: $transition-duration;
    transition-property: fill;
  }
}

.button {
  @include font(AmaticSC, bold);
  color: $gray44;
  font-size: 2.5rem;
  padding: 0.9rem 2.5rem 1rem;
  position: relative;
  text-transform: uppercase;

  &[disabled] {
    opacity: 0.5;
  }

  &:not([disabled]):hover {
    path:nth-of-type(2) {
      fill: darken($white, 10%);
    }
  }

  @include media(lg) {
    font-size: 2rem;
    padding: 0.65rem 2rem 0.75rem;
  }

  @include media(sm) {
    font-size: 1.75rem;
  }

  @include media(xs) {
    font-size: 1.5rem;
    padding: 0.4rem 1.5rem 0.5rem;
  }
}

.label {
  z-index: 1;
}
