@import "../../theme";

$primary: $green8A;
$secondary: $green35;

.main {
  background: $primary;
  color: $white;
}

.navItem {
  color: $primary;
}

.secondary {
  color: $secondary;
}
