@import "../../../theme";
@import "../../../theme/media";

.grid {
  text-align: center;
}

@mixin gridItemContent {
  margin: 0 auto;

  @include media(md) {
    max-width: inherit;
  }
}

.gridItemContentLeft {
  @include gridItemContent;
  max-width: 11rem;
}

.gridItemContentRight {
  @include gridItemContent;
  max-width: 21rem;
}

.img {
  height: 15rem;
  margin: 2rem 0 0.5rem 0;
}
