@import "../../../theme";
@import "../../../theme/media";

.paragraph {
  font-weight: 700;
  margin-bottom: 2rem;

  @include media(md) {
    margin-bottom: 1rem;
  }
}
