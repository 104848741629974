@import "./theme";
@import "./theme/media";

.nav {
  border-bottom: 1px solid $grayC4;
  display: grid;
  grid-template-columns: 25rem minmax(0, 1fr) 15rem;

  @include media(lg) {
    grid-template-columns: 12.5rem minmax(0, 1fr) 10rem;
  }

  @include media(md) {
    grid-template-columns: minmax(0, 1fr);
  }
}

.logo {
  align-self: center;
  padding: 1rem 0 1rem 3rem;
  width: 80%;

  @include media(lg) {
    padding: 0.75rem;
    width: 100%;
  }

  @include media(md) {
    display: none;
  }
}

.modules {
  align-items: flex-start;
  display: flex;
  justify-content: space-evenly;
  padding: 0.75rem 0;
}

.module {
  @include font(Museo, 700);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
  transition-duration: 150ms;
  transition-property: background;

  &[disabled] {
    filter: grayscale(1);
    opacity: 0.25;
  }

  img {
    height: 4rem;
    width: 4rem;

    @include media(lg) {
      height: 3rem;
      width: 3rem;
    }

    @include media(md) {
      height: 2rem;
      width: 2rem;
    }
  }

  span {
    font-size: 1rem;
    font-weight: 700;

    @include media(sm) {
      display: none;
    }
  }

  @include media(lg) {
    padding: 0 0.25rem;
  }
}
