@import "../../theme";
@import "../../theme/media";

.img[data-mode="avatar-md"] {
  height: 10rem;

  @include media(lg) {
    height: 7.5rem;
  }

  @include media(xs) {
    height: inherit;
    max-width: 6rem;
    width: 100%;
  }
}

.img[data-mode="avatar-lg"] {
  height: 15rem;

  @include media(lg) {
    height: 10rem;
  }

  @include media(xs) {
    height: inherit;
    max-width: 6rem;
    width: 100%;
  }
}

.img[data-mode="fullscreen"] {
  max-width: 56rem;
  width: 100%;
}
