@import "../theme";
@import "../theme/media";

.grid {
  @include font(Museo, 700);
  align-items: center;
  background: $grayC9;
  display: grid;
  font-size: 1.25rem;
  grid-column-gap: 0.75rem;
  grid-template-columns: 1rem minmax(0, 1fr);
  padding: 1rem 3rem;

  @include media(lg) {
    font-size: 1rem;
    padding: 0.75rem;
  }

  @include media(md) {
    display: none;
  }
}

.header {
  grid-column: 1 / span 2;
  margin-bottom: 0.25rem;
}

.icon {
  height: 1rem;
  width: 1rem;
}

.signOut {
  @include font(Museo, 700);
  font-size: 1.25rem;

  @include media(lg) {
    font-size: 1rem;
  }

  &:hover {
    text-decoration: underline;
  }
}
