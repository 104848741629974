@import "../../theme";
@import "../../theme/media";

.title {
  margin-bottom: 2.5rem;

  @include media(md) {
    margin-bottom: 1rem;
  }
}

.paragraph {
  margin: 1rem 0;
  text-align: left;
}

.grid {
  align-items: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 5rem minmax(0, 1fr);

  .paragraph {
    margin: 0;
  }

  @include media(sm) {
    grid-auto-flow: row;
    grid-gap: 1.75rem;
    grid-template-columns: minmax(0, 1fr);
  }
}

.img {
  height: 5rem;
  margin: 0 auto;

  @include media(lg) {
    height: inherit;
    max-width: 3rem;
    width: 100%;
  }
}
