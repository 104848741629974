$black: #000000;
$blue00: #00aec3;
$blue03: #034147;
$gray22: #222222;
$gray44: #444444;
$grayC4: #c4c4c4;
$grayC9: #c9e9e6;
$grayEE: #eeeeee;
$green01: #013a41;
$green03: #034148;
$green1E: #1e8840;
$green2B: #2b5c5b;
$green35: #354f1b;
$green8A: #88b75b;
$greenB1: #b1f56e;
$red70: #701328;
$red: #f1677c;
$redF7: #f75507;
$teal: #75b7b7;
$white: #ffffff;
$yellow59: #594312;
$yellowFD: #fdaf18;

@mixin font($family, $weight) {
  font-family: $family, sans-serif;
  font-style: normal;
  font-weight: $weight;
}
