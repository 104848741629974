@import "../../theme";
@import "../../theme/media";

.subtitle {
  @include font(AmaticSC, bold);
  font-size: 3.75rem;
  line-height: 3.5rem;
  margin: 0;
  text-align: center;

  @include media(lg) {
    font-size: 2.5rem;
    line-height: 2.4rem;
  }

  @include media(sm) {
    font-size: 2.25rem;
    line-height: 2.2rem;
  }

  @include media(xs) {
    font-size: 2rem;
    line-height: 1.9rem;
  }
}

.paragraphAlt {
  @include font(Museo, 700);
  align-items: center;
  display: grid;
  font-size: 1.5rem;
  grid-column-gap: 0.75rem;
  grid-template-columns: min-content 2rem minmax(0, 1fr);
  grid-template-rows: repeat(2, minmax(0, 1fr));
  justify-content: center;
  margin: 1.5rem 0;

  @include media(md) {
    font-size: 20px;
    line-height: 25px;
  }

  @include media(sm) {
    display: block;
  }
}

.paragraphAltHeader {
  grid-column: 1;
  grid-row: 1 / span 2;
}

.paragraphAltCurlyBraceContainer {
  grid-column: 2;
  grid-row: 1 / span 2;

  @include media(sm) {
    display: none;
  }
}

.paragraphAltCurlyBrace {
  display: flex;
  height: 6.8rem;

  @include media(lg) {
    height: 5rem;
  }
}

.paragraphAltLineTop {
  font-weight: 700;
  grid-column: 3;
  grid-row: 1;
}

.paragraphAltLineBottom {
  font-weight: 700;
  grid-column: 3;
  grid-row: 2;
}

.paragraphAltBullet {
  margin-right: 15px;
  width: 19px;
}

.linkContainer {
  @include font(Museo, 700);
  font-size: 2rem;
  margin: 1.5rem 0 2rem 0;
  text-align: center;

  a {
    word-break: break-all;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }

  @include media(lg) {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }

  @include media(sm) {
    margin: 1rem 0;
  }
}
