@import "./theme";
@import "./theme/media";

@mixin heading {
  margin: 1.5rem 0;
  text-align: center;
  text-transform: uppercase;
}

.animation {
  align-content: center;
  display: grid;
  height: 25rem;
  justify-content: center;
  margin: 0 auto;
  max-width: 39rem;
  width: 100%;

  canvas {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
  }

  &.fullscreen {
    align-content: start;
    height: 100vh;
    max-width: inherit;
    overflow: hidden;

    canvas {
      width: auto;
    }
  }

  @include media(md) {
    height: 20rem;
    max-width: 31.2rem;
  }

  @include media(sm) {
    height: 15rem;
    max-width: 23.4rem;
  }
}
