@import "../../theme";
@import "../../theme/media";

.title {
  margin-bottom: 5rem;

  @include media(lg) {
    margin-bottom: 3rem;
  }

  @include media(sm) {
    margin-bottom: 2rem;
  }

  @include media(xs) {
    margin-bottom: 1rem;
  }
}

.list {
  @include font(Museo, 500);
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0 auto;
  max-width: 800px;
  padding-left: 1rem;

  @include media(lg) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.listItem {
  margin: 2rem 0;

  @include media(lg) {
    margin: 1rem 0;
  }

  @include media(sm) {
    margin: 0.75rem 0;
  }

  @include media(xs) {
    margin: 0.5rem 0;
  }
}
