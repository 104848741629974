$lg: 100em; // Desktop
$md: 72em; // Tablet (landscape)
$sm: 56em; // Tablet (portrait)
$xs: 31em; // Smartphone

@mixin media($size) {
  @if $size == lg {
    @media screen and (max-width: $lg) {
      @content;
    }
  } @else if $size == md {
    @media screen and (max-width: $md) {
      @content;
    }
  } @else if $size == sm {
    @media screen and (max-width: $sm) {
      @content;
    }
  } @else if $size == xs {
    @media screen and (max-width: $xs) {
      @content;
    }
  }
}
